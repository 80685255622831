import React from "react";
import { Routes, Route } from 'react-router-dom';
import QRCodePage from './pages/QRCodePage';
import EnrollPage from './pages/EnrollPage';
import ManagePreferences from './pages/ManagePreferences';
import LutherPark from './pages/LutherPark';
import Unsubscribe from './pages/Unsubscribe'; 
import EmptyComponent from './components/EmptyComponent';
import { ConfigProvider } from 'antd';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig'; 
import VerifyPage from "pages/VerifyPage";
 
const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className="app-layout">
    <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} 
      insertionPoint={document.getElementById('styles-insertion-point')}>
        <ConfigProvider>
          <Routes>
            <Route exact path="/" element={<EmptyComponent />} /> 
            <Route exact path="/:account_slug/enroll" element={<EnrollPage />} /> 
            <Route exact path="/:account_slug/verify" element={<VerifyPage />} /> 
            {/*
            <Route exact path="/lutherpark/enroll" element={<LutherPark />} />
            <Route exact path="/:account_slug" element={<EmptyComponent />} /> 
            <Route exact path="/:account_slug/qr" element={<QRCodePage />} /> 
            <Route exact path="/:account_slug/enroll" element={<EnrollPage />} /> 
            <Route exact path="/manage" element={<ManagePreferences />} />
            <Route exact path="/unsubscribe" element={<Unsubscribe />} />
  */}
            {/*
            <Route exact path="/:account_id/manage" element={<ManagePreferences />} /> 
            <Route exact path="/:account_id/enroll" element={<ManagePreferences />} /> 
            <Route exact path="/:account_id/unsubscribe" element={<ManagePreferences />} /> 
            <Route exact path="/:account_id/unsubscribe/:token" element={<ManagePreferences />} /> 
            */}
          </Routes>
        </ConfigProvider>
      </ThemeSwitcherProvider>
    </div>
  );
}

export default App;
