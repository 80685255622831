import React from 'react'
import { DatePicker, InputNumber, Checkbox, Alert, Upload, Select, Switch, Input, Col, Row, Form, Button } from 'antd';
import {
    UploadOutlined,
} from '@ant-design/icons';
import Flex from '../Flex';
const { Option } = Select;
const { TextArea } = Input;


const CustomizedFields = (props) => {

    const beforeUpload = async (file) => {
        return false;
    }

    const getFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        //setUploadPhoto(e.fileList)
        return e && e.fileList;
    };


    const { elm, disabled } = props;
        return (
            <>
                {elm.data_type === "header-text" && (<>
                    <Flex justifyContent="center" flexDirection="column" alignItems="center" className="py-2">
                        {/* <h4>{elm.text}</h4> */}
                    </Flex>
                </>)}


                {elm.data_type === "single-line-text" && (<>
                    <Form.Item
                        className={`app-request-input-item ${!disabled ? 'cursor-text' : ''}`}
                        label={elm.label}
                        name={["form_fields", elm.sort_order, elm.api_name]}
                        rules={[{
                            required: elm.required, message: `${elm.label} is Required`
                        }]}
                        help={elm.configuration?.description ? elm.configuration?.description : null}
                    >
                        <Input disabled={disabled} />
                    </Form.Item>

                </>)}

                {elm.data_type === "multi-line-text" && (<>
                    <Form.Item
                        className={`app-request-input-item ${!disabled ? 'cursor-text' : ''}`}
                        label={elm.label}
                        name={["form_fields", elm.sort_order, elm.api_name]}
                        rules={[{
                            required: elm.required, message: `${elm.label} is Required`
                        }]}
                        help={elm.configuration?.description ? elm.configuration?.description : null}
                    >
                        <TextArea disabled={disabled} />
                    </Form.Item>
                </>)}

                {elm.data_type === "number" && (<>
                    <Form.Item
                        className={`app-request-input-item ${!disabled ? 'cursor-text' : ''}`}
                        label={elm.label}
                        name={["form_fields", elm.sort_order, elm.api_name]}
                        rules={[{
                            required: elm.required, message: `${elm.label} is Required`
                        }]}
                        help={elm.configuration?.description ? elm.configuration?.description : null}
                    >
                        <InputNumber disabled={disabled} style={{ width: '100%' }} />
                    </Form.Item>
                </>)}

                {elm.data_type === "boolean" && (<>
                    <Form.Item
                        className={`app-request-input-item ${!disabled ? 'cursor-text' : ''}`} 
                        name={["form_fields", elm.sort_order, elm.api_name]}
                        rules={[
                          {
                            validator: (_, value) => {
                              if(elm.required) {
                                return value ? Promise.resolve() : Promise.reject(new Error(`${elm.label} is Required`))
                              } else {
                                return Promise.resolve();
                              }
                            } 
                          },
                        ]}
                        help={elm.configuration?.description ? elm.configuration?.description : null}
                        valuePropName="checked"
                    >
                        <Checkbox disabled={disabled}>
                        {elm.label}
                        </Checkbox>
                    </Form.Item>
                </>)}


                {elm.data_type === "multiple-choice" && (<>

                    <Form.Item
                        className={`app-request-input-item ${!disabled ? 'cursor-text' : ''}`}
                        label={elm.label}
                        name={["form_fields", elm.sort_order, elm.api_name]}
                        rules={[{
                            required: elm.required, message: `${elm.label} is Required`
                        }]}
                        help={elm.configuration.description ? elm.configuration.description : null}
                    >
                        <Checkbox.Group style={{ width: '100%' }}  >
                            {elm.options.length <= 0 ?
                                <Row gutter={24}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Alert message="No Options Found" type="info" />
                                    </Col>
                                </Row>
                                :
                                elm.options.map(elm => (
                                    <>
                                        <Checkbox disabled={disabled} value={elm.value}>{elm.value}</Checkbox>
                                        <br />
                                    </>
                                ))
                            }
                        </Checkbox.Group>
                    </Form.Item>
                </>)}

                {elm.data_type === "dropdown" && (<>
                    <Form.Item
                        className={`app-request-input-item ${!disabled ? 'cursor-text' : ''}`}
                        label={elm.label}
                        name={["form_fields", elm.sort_order, elm.api_name]}
                        rules={[{
                            required: elm.required, message: `${elm.label} is Required`
                        }]}
                        help={elm.configuration.description ? elm.configuration.description : null}
                    >
                        {elm.options.length <= 0 ?
                            <Row gutter={24}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Alert message="No Options Found" type="info" />
                                </Col>
                            </Row>
                            :
                            <Select style={{ width: '100%' }} disabled={disabled}>
                                {elm.options.map(elm => (
                                    <Option disabled={disabled} value={elm.value}>{elm.value}</Option>
                                ))}
                            </Select>
                        }
                    </Form.Item>
                </>)}

                {elm.data_type === "date-time" && (<>
                    <Form.Item
                        className={`app-request-input-item ${!disabled ? 'cursor-text' : ''}`}
                        label={elm.label}
                        name={["form_fields", elm.sort_order, elm.api_name]}
                        rules={[{
                            required: elm.required, message: `${elm.label} is Required`
                        }]}
                        help={elm.configuration?.description ? elm.configuration?.description : null}
                    >

                        {!elm.configuration?.date_only ?
                            <DatePicker
                                disabled={disabled}
                                style={{ width: '100%' }}
                                format="YYYY-MM-DD HH:mm"
                                showTime={true}
                                placeholder={"Select date and time"}
                            />
                            :
                            <DatePicker
                                disabled={disabled}
                                style={{ width: '100%' }}
                                placeholder={"Select date"}
                            />
                        }


                    </Form.Item>
                </>)}

                {elm.data_type === "file" && (<>
                    <Form.Item
                        className={`app-request-input-item ${!disabled ? 'cursor-text' : ''}`}
                        label={elm.label}
                        name={["form_fields", elm.sort_order, elm.api_name]}
                        rules={[{
                            required: elm.required, message: `${elm.label} is Required`
                        }]}
                        help={elm.configuration?.description ? elm.configuration?.description : null}
                        valuePropName='fileList'
                        getValueFromEvent={getFile}
                    >
                        <Upload
                            beforeUpload={beforeUpload}
                        >
                            <Button block icon={<UploadOutlined />} type="primary"  >Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                </>
                )}


            </>
        )
    }


    export default CustomizedFields