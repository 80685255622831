import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Flex from "components/shared-components/Flex";
import FAIcon from 'components/util-components/FAIcon';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { Form, Button, Col, Input, Row, Tooltip, message } from 'antd';
import { ROW_GUTTER } from 'constants/ThemeConstant'; 
import CustomizedFields from 'components/shared-components/CustomizedFields';
import { PowerByLogo } from 'components/shared-components/PowerByLogo';
import RequestEnable from 'pages/PublicRequest/RequestEnable';
import ThankYou from 'components/ThankYou';
import { isMoment } from 'moment-timezone';
import _ from 'lodash'

function EmptyComponent() {


  const handleClick = () => {
    window.location.href = 'https://pushpulse.com';
  };

    return (
        <div>
            {/*
            {data?.configDetails?.favicon &&
                <Helmet>
                    <link rel="shortcut icon" type="image/x-icon" href={`${data?.configDetails?.favicon}`} />
                </Helmet>
            }
            */}
            <>
                <div className='request-header-bg' style={{ backgroundColor: '#54b3e4' }} />

                <div className='request-form-body'>
                    <div className='container'>
                        <Flex justifyContent="center" flexDirection="column" alignItems="center" className="py-2 mb-2">
                            <a
                                href="https://pushpulse.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={'/logo.png'} alt="logo" style={{ width: '250px', paddingTop: "30px", paddingBottom: "10px" }} />
                            </a>
                        </Flex>

                        <Flex justifyContent="center" flexDirection="column" alignItems="center">
                            <div className="text-center">
                                <h2>Critical Communications. Delivered.</h2>
                                <h4>PushPulse is a critical communications platform that helps organizations modernize the way they share important alerts and notifications.</h4>
                                <img src={'/tools.webp'} alt="logo" style={{ width: '350px', paddingTop: "30px", paddingBottom: "10px" }} />

                                <Button block type="primary" onClick={handleClick}>Learn More</Button>
                            </div>
                        </Flex>
                    </div>
                </div>
                <div className='request-footer'>
                    <div className="container">
                        <Flex justifyContent="center" flexDirection="column" alignItems="center" className="py-2">
                            <a
                                href="https://pushpulse.com"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <PowerByLogo show_branding={true} />
                            </a>
                        </Flex>
                    </div>
                </div>
            </>
        </div>
    );
}

export default EmptyComponent;
