import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Flex from "components/shared-components/Flex";
import FAIcon from 'components/util-components/FAIcon';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { Switch, Form, Button, Col, Input, Row, Tooltip, message, Modal, Alert, Space, Typography, Checkbox } from 'antd';
import { endpoints } from "../../api/index"
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { PowerByLogo } from 'components/shared-components/PowerByLogo'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faMobile, faComments, faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import 'react-phone-number-input/style.css'
import { AsYouType, isPossiblePhoneNumber } from 'libphonenumber-js'
import parsePhoneNumber from 'libphonenumber-js'
import inputvalidator from 'validator';
import { motion } from 'framer-motion'
import { useLocation } from 'react-router-dom';
import useDeviceType from '../../hooks/useDeviceType';

const { Text } = Typography;


const VerifyPage = props => {

    let { account_slug } = useParams();
    const deviceType = useDeviceType();
    const [accountDetails, setAccountDetails] = useState(null);
    const [accountError, setAccountError] = useState(false)
    const [validating, setValidating] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    const [token, setToken] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        setToken(token);
    }, [location]);



    useEffect(() => {
        const getAccountDetails = async () => {
            await endpoints.accountLookup(account_slug)
                .then(async res => {
                    setAccountDetails(res)
                })
                .catch(err => {
                    setAccountError(true)
                });
        };
        getAccountDetails();

    }, [account_slug])

    useEffect(() => {
        if (token) {
            const verifyToken = async () => {
                setValidating(true)
                await endpoints.verifyToken(token)
                    .then(async res => {
                        setValidating(false)
                        setConfirmed(true)
                    })
                    .catch(err => {
                        setValidating(false)
                        setConfirmed(false)
                    });
            };
            verifyToken();
        } else {
            setConfirmed(false)
        }

    }, [token])


    const handleDownload = (e, platform) => {
        e.preventDefault()
        const url = platform === 'ios' ? 'https://apps.apple.com/us/app/pushpulse/id1669930639' : 'https://play.google.com/store/apps/details?id=com.pushpulse.app'
        window.open(url, '_blank');
    };

    const LogoComponent = () => {

        const currentTheme = 'light';
        let logo = '/logo.png';

        if (!accountDetails) {
            return (
                <img src={logo} alt="logo" style={{ width: '250px', paddingTop: "30px", paddingBottom: "30px" }} />
            )
        } else {
            if (accountDetails?.branding?.dark_logo || accountDetails?.branding?.light_logo) {

                if (currentTheme === 'light') {
                    logo = accountDetails?.branding?.dark_logo ? accountDetails?.branding?.dark_logo : accountDetails?.branding?.light_logo
                } else {
                    logo = accountDetails?.branding?.light_logo ? accountDetails?.branding?.light_logo : accountDetails?.branding?.dark_logo
                }
                return (
                    <img src={logo} alt="logo" style={{ width: '250px', paddingTop: "30px", paddingBottom: "30px" }} />
                )

            } else {
                return (
                    <h2 style={{ fontSize: 40, fontWeight: "400", textAlign: 'center', paddingBottom: 10 }}> {accountDetails?.account?.name.toUpperCase()}</h2>
                )
            }
        }
    }


    return (
        <div>
            {accountError ?
                <div className='request-header-bg' style={{ backgroundColor: '#54b3e4' }} />
                :
                <div className='request-header-bg' style={{ backgroundColor: accountDetails?.branding?.bg_color || '#54b3e4' }} />
            }

            <div className='request-form-body  '>

                <div className=' container pb-3' style={{ minHeight: '400px' }}>
                    <Flex justifyContent="center" flexDirection="column" alignItems="center" className="py-2">
                        <LogoComponent />
                        {accountError
                            &&
                            <h2 style={{ color: '#000000' }}><FontAwesomeIcon icon={faTriangleExclamation} /> Unable to Locate Organization</h2>
                        }
                    </Flex>
                    <>


                        {(!confirmed && !validating) &&
                            <div className="my-2 ">
                                <div className="text-center">
                                    <h3 className="mt-3 app-auth-header-text">Your token is either expired or not valid.</h3>
                                    <h4>If this is incorrect, please verify the link in your email.</h4>
                                </div>
                            </div>
                        }

                        {(validating && !confirmed) &&
                            <div className="my-2 ">
                                <div className="text-center">
                                    <h3 className="mt-3 app-auth-header-text">Verifying Enrollment</h3>
                                </div>
                                <div className={' mt-3 text-center'}>
                                    <div>
                                        <div className=" align-items-center">
                                            <h1 className="mb-0 font-weight-bold"><FAIcon icon={faSpinner} className='fa-spin' /></h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {confirmed &&
                            <>
                                <div className="my-2 ">
                                    <div className="text-center">
                                        <h3 className="mt-3 app-auth-header-text">Enrollment Verified</h3>
                                        <p className='lead'>Stay Connected On the Go with PushPulse Mobile App. Stay connected with your team, receive important updates, and collaborate effortlessly, no matter where you are. Scan the QR code or click the download link for the App Store or Google Play to get started.</p>
                                    </div>
                                </div>
                                <Row justify="center" className="align-items-stretch h-50">
                                    <Col xs={24} sm={24} md={24} lg={24}>
                                        <div className="container d-flex flex-column justify-content-center h-100">
                                            <Row justify="center">
                                                <div className="mt-1">
                                                    <Row gutter={12} className="mt-3" justify='center'>
                                                        <Col sm={24} md={12} lg={12}>
                                                            <div className=' text-center'>
                                                                <img className="img-fluid" src="/app-screenshots.png" alt="QR" />
                                                            </div>
                                                        </Col>
                                                        <Col sm={24} md={12} lg={12}>
                                                            {deviceType === 'desktop' &&
                                                                <div className=' text-center'>
                                                                    <img style={{ maxWidth: '190px', height: 'auto' }} src="/app-qr-code.png" alt="QR" />
                                                                </div>
                                                            }
                                                            <div className=' text-center'>
                                                                <a href="/#" onClick={(e) => handleDownload(e, 'ios')}>
                                                                    <img className="mt-2 mb-1" height="44" src="/appstore.png" alt="QR" />
                                                                </a> <br />

                                                                <a href="/#" onClick={(e) => handleDownload(e, 'android')}>
                                                                    <img className="mt-1 mb-1" height="44" src="/googleplay.png" alt="QR" />
                                                                </a>
                                                                <br />

                                                            </div>

                                                        </Col>
                                                    </Row >
                                                </div>

                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                    </>
                </div>
            </div>

            <div className='request-footer'>
                <div className="container">
                    <Flex justifyContent="center" flexDirection="column" alignItems="center" className="py-2">
                        <PowerByLogo show_branding={!accountDetails ? true : accountDetails?.branding?.platform_branding} />
                    </Flex>
                </div>
            </div>
        </div>
    );
}

export default VerifyPage;
