import axios from 'axios';
// import Utils from 'utils';
//import { toast } from 'react-toast';

//setup axios base url
const baseUrl = process.env.REACT_APP_API_BASE_URL || "https://api.pushpulse.com";

axios.interceptors.response.use(undefined, (error) => {
    const errors = error?.response?.data; 
    throw errors; 
});

const responseData = (response) => response.data;

const requestHeaders = (protectedRoute, formData) => {

    let headers = {}
    headers['Accept'] = 'application/json';
    if (protectedRoute) {
        // headers['X-PushPulse-AuthToken'] = Utils.getSessionToken();
    }
    if (formData) {
        headers['Content-Type'] = 'multipart/form-data';
    }
    return {
        headers
    };
}

//http http_requests facade
const http_requests = {
    get: (protectedRoute, url) => axios.get(url, requestHeaders(protectedRoute)).then(responseData),
    post: (protectedRoute, url, object) => axios.post(url, object, requestHeaders(protectedRoute)).then(responseData),
    delete: (protectedRoute, url) => axios.delete(url, requestHeaders(protectedRoute)).then(responseData),
    put: (protectedRoute, url, object) => axios.put(url, object, requestHeaders(protectedRoute)).then(responseData),
    postFormData: (protectedRoute, url, file) => {
        var formData = new FormData();
        formData.append('file', file);
        return axios.post(url, formData, requestHeaders(protectedRoute, true))
    },
    postFormDataPrepared: (protectedRoute, url, object) => axios.post(url, object, requestHeaders(protectedRoute, true))
}

export const endpoints = {  
    accountLookup: (slug) => http_requests.get(true, `${baseUrl}/v1/apps/selfserve/lookup?org=${slug}`),
    enrollUser: (formData) => http_requests.post(true, `${baseUrl}/v1/apps/selfserve/enroll`, formData),
    verifyToken: (token) => http_requests.get(true, `${baseUrl}/v1/apps/selfserve/verify?token=${token}`),
} 