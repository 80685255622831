import { useState, useEffect } from 'react';
import Utils from 'utils';




const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState('desktop'); 
  const getDeviceType = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    // Check for iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'ios';
    }
  
    // Check for Android
    if (/android/i.test(userAgent)) {
      return 'android';
    }
  
    // Default to desktop
    return 'desktop';
  }

  useEffect(() => {
    const device = getDeviceType();
    setDeviceType(device);
  }, []);

  return deviceType;
};

export default useDeviceType;