import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Flex from "components/shared-components/Flex";
import FAIcon from 'components/util-components/FAIcon';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { Switch, Form, Button, Col, Input, Row, Tooltip, message, Modal } from 'antd';
import { endpoints } from "../../api/index"
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { PowerByLogo } from 'components/shared-components/PowerByLogo'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faMobile, faComments, faEnvelope } from '@fortawesome/pro-regular-svg-icons'


function PublicQuote() {



    const renderMainContent = () => {

        return (
            <>
                <div className=' container pb-3'>
                    <Flex justifyContent="center" flexDirection="column" alignItems="center" className="py-2">
                        <a
                            href="https://pushpulse.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={'/logo.png'} alt="logo" style={{ width: '250px', paddingTop: "30px", paddingBottom: "30px" }} />
                        </a>

                    </Flex>

                    {/*********************** */}

                    <Flex justifyContent="center" flexDirection="column" alignItems="center" className="py-2">
                        <h2>Unsubscribe</h2>
                        <p>Please enter a valid email address or phone number</p>

                        <Form
                            layout="horizontal"
                            requiredMark={false}
                        >
                            <Row gutter={ROW_GUTTER}>
                                <Col xs={24} sm={24} md={24} lg={24}>

                                    <Row gutter={ROW_GUTTER}>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Form.Item
                                                rules={[
                                                    { required: true, message: "Email Address is Required" }
                                                ]}
                                                name={"email"}>
                                                <Input placeholder="Email Address" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24}>
                                            <Form.Item
                                                rules={[
                                                    { required: true, message: "Phone Number is Required" }
                                                ]}
                                                name={"phone"}>
                                                <Input placeholder="Phone Number" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Flex>

                    <Button block type="primary">Update Preferences</Button>
                    {/************************ */}
                </div >
            </>
        )
    }

    return (
        <div>
            {/*
            {data?.configDetails?.favicon &&
                <Helmet>
                    <link rel="shortcut icon" type="image/x-icon" href={`${data?.configDetails?.favicon}`} />
                </Helmet>
            }
            */}
            <>
                <div className='request-header-bg' style={{ backgroundColor: '#54b3e4' }} />

                <div className='request-form-body'>
                    {renderMainContent()}


                </div>
                <div className='request-footer'>
                    <div className="container">
                        <Flex justifyContent="center" flexDirection="column" alignItems="center" className="py-2">
                            {/* {!requestSubmitted && <Button block type="primary" onClick={handleSubmit} loading={createRequestLoadingIndicator}>Submit</Button>} */}
                            <PowerByLogo show_branding={true} />
                        </Flex>
                    </div>
                </div>
            </>
        </div>
    );
}

export default PublicQuote;
