import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export class FAIcon extends Component {
    render() {
        const { icon, className } = this.props;
        return (
            <><FontAwesomeIcon icon={icon} className={className} style={{margin: 'auto'}}/></>
        )
    }
}

export default FAIcon
