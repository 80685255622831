import React from "react";
import { useEffect , useState} from "react";

export  const PowerByLogo = (props) =>{
    const { show_branding } = props;
    
    const [imgSrc, setImgSrc] = useState();
    useEffect(() =>{
        if(show_branding ){
          setImgSrc("/poweredby.png")
        }
    },[show_branding])


    return(<>
        {imgSrc && <img src={imgSrc} alt="LOGO" style={{paddingTop: '30px', paddingBottom: '50px', width: '250px' }} />}
    </>)
}
