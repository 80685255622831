import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Flex from "components/shared-components/Flex";
import FAIcon from 'components/util-components/FAIcon';
import { faCircleInfo, faEnvelopeBadge } from '@fortawesome/pro-solid-svg-icons'
import { Switch, Form, Button, Col, Input, Row, Tooltip, message, Modal, Alert, Space, Typography, Checkbox } from 'antd';
import { endpoints } from "../../api/index"
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { PowerByLogo } from 'components/shared-components/PowerByLogo'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faMobile, faComments, faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import CountryPhoneInput, { ConfigProvider } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import 'react-phone-number-input/style.css'
import { AsYouType, isPossiblePhoneNumber } from 'libphonenumber-js'
import parsePhoneNumber from 'libphonenumber-js'
import inputvalidator from 'validator';
import { motion } from 'framer-motion'
const { Text } = Typography;

function EnrollPage() {

    let { account_slug } = useParams();
    const [form] = Form.useForm();
    const [accountDetails, setAccountDetails] = useState(null);
    const [accountError, setAccountError] = useState(false)
    const [loadingIndicator, setLoadingIndicator] = useState(false);
    const [error, setError] = useState(false);
    const [submitted, setSubmitted] = useState(false)
    const supportedPhones = [
        'Canada',
        'Mexico',
        'United States of America',
        'United States Minor Outlying Islands',
        'Virgin Islands (U.S.)',
    ]
    const rules = {
        first_name: [
            {
                required: true,
                message: 'Please enter your first name'
            }
        ],
        last_name: [
            {
                required: true,
                message: 'Please enter your last name'
            }
        ],
        email: [
            {
                required: true,
                message: 'Please enter your email address'
            },
            {
                type: 'email',
                message: 'Please enter a valid email!'
            }
        ],
        password: [
            {
                min: 8,
                max: 30,
                required: true,
                message: 'Please enter a password between 8 and 30 characters long',
            },
            {
                pattern: /^(?=.*[a-z])/,
                message: 'Password must include at least one lowercase letter',
            },
            {
                pattern: /^(?=.*[A-Z])/,
                message: 'Password must include at least one uppercase letter',
            },
            {
                pattern: /^(?=.*\d)/,
                message: 'Password must include at least one number',
            },
            {
                pattern: /^(?=.*[@$!%*?&])/,
                message: 'Password must include at least one symbol',
            },
        ],
        terms: [
            {
                required: true,
                message: 'Please accept agreements'
            }
        ]
    }

    useEffect(() => {
        const getAccountDetails = async () => {
            await endpoints.accountLookup(account_slug)
                .then(async res => {
                    setAccountDetails(res)
                })
                .catch(err => {
                    setAccountError(true)
                });
        };
        getAccountDetails();

    }, [account_slug])

    const [showResend, setShowResend] = useState(true)
    const handleClick = async () => {
        setShowResend(false)
    };


    const onEnroll = async () => {
        setError(false);
        setLoadingIndicator(true);

        form.validateFields().then(async function (values) {
            console.log(values)


            if (values?.sms?.phone) {
                const phoneNumber = parsePhoneNumber(values?.sms?.phone, values?.sms?.short)
                if (phoneNumber) {
                    let destination = null
                    if (phoneNumber?.ext) {
                        destination = `${phoneNumber.format('E.164')}x${phoneNumber?.ext}`;
                    } else {
                        destination = phoneNumber.format('E.164')
                    }
                    values.sms = destination
                } else {
                    values.sms = null;
                }
            } else {
                values.sms = null
            }


            if (values?.voice?.phone) {
                const phoneNumber = parsePhoneNumber(values?.voice?.phone, values?.voice?.short)
                if (phoneNumber) {
                    let destination = null
                    if (phoneNumber?.ext) {
                        destination = `${phoneNumber.format('E.164')}x${phoneNumber?.ext}`;
                    } else {
                        destination = phoneNumber.format('E.164')
                    }
                    values.voice = destination
                } else {
                    values.voice = null;
                }
            } else {
                values.voice = null
            }

            const enrollment_params = {
                account_id: accountDetails?.account?.id,
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                password: values.password,
                sms: values.sms,
                voice: values.voice,
            }
            console.log(enrollment_params)

            await endpoints.enrollUser(enrollment_params).then(async (resp) => {
                setLoadingIndicator(false);
                setSubmitted(true)
                console.log("resp")
                console.log(resp)
            })

        }).catch(error => {
            setError(true)
            setLoadingIndicator(false);
        });
    }

    const LogoComponent = () => {

        const currentTheme = 'light';
        let logo = '/logo.png';

        if (!accountDetails) {
            return (
                <img src={logo} alt="logo" style={{ width: '250px', paddingTop: "30px", paddingBottom: "30px" }} />
            )
        } else {
            if (accountDetails?.branding?.dark_logo || accountDetails?.branding?.light_logo) {

                if (currentTheme === 'light') {
                    logo = accountDetails?.branding?.dark_logo ? accountDetails?.branding?.dark_logo : accountDetails?.branding?.light_logo
                } else {
                    logo = accountDetails?.branding?.light_logo ? accountDetails?.branding?.light_logo : accountDetails?.branding?.dark_logo
                }
                return (
                    <img src={logo} alt="logo" style={{ width: '250px', paddingTop: "30px", paddingBottom: "30px" }} />
                )

            } else {
                return (
                    <h2 style={{ fontSize: 40, fontWeight: "400", textAlign: 'center', paddingBottom: 10 }}> {accountDetails?.account?.name.toUpperCase()}</h2>
                )
            }
        }
    }


    return (
        <div>
            {accountError ?
                <div className='request-header-bg' style={{ backgroundColor: '#54b3e4' }} />
                :
                <div className='request-header-bg' style={{ backgroundColor: accountDetails?.branding?.bg_color || '#54b3e4' }} />
            }

            <div className='request-form-body  '>

                <div className=' container pb-3' style={{ minHeight: '400px' }}>
                    <Flex justifyContent="center" flexDirection="column" alignItems="center" className="py-2">
                        <LogoComponent />
                        {accountError
                            &&
                            <h2 style={{ color: '#000000' }}><FontAwesomeIcon icon={faTriangleExclamation} /> Unable to Locate Organization</h2>
                        }
                    </Flex>
                    {submitted &&  
                        <> 
                            <div className="my-2 ">
                                <div className="text-center">
                                    <h3 className="mt-3 app-auth-header-text">Verify Your Email Address</h3>
                                    <h4>Check your email and click the link to activate your account.</h4>
                                </div>
                                <div className={' mt-3 text-center'}>
                                    <div>
                                        <div className=" align-items-center">
                                            <h1 className="mb-0 font-weight-bold" style={{fontSize: 100}}><FAIcon icon={faEnvelopeBadge} /></h1>
                                        </div>
                                    </div>
                                </div>
                                {/*
                                <Button block type="primary" onClick={handleClick}>Resend Email</Button>
                                */}
                            </div>
                        </>
                    }


                    {(!submitted && accountDetails) &&
                        <>
                            <Flex justifyContent="center" flexDirection="column" alignItems="center" className="py-2">
                                <h2>{accountDetails?.account?.name} Alerts</h2>
                                <p>Register to receive emergency communications and other important informatin via text message and email from {accountDetails?.account?.name}.</p>
                                <p>Standard or other messaging charges apply depending upon your wireless carrier plan and subscription details. Once registered, you can opt out of notifications at any time.</p>
                            </Flex>

                            <Flex justifyContent="start" flexDirection="column" alignItems="start" className="py-2">
                                <ConfigProvider
                                    locale={en}
                                    areaFilter={(area) => supportedPhones.includes(area.name)}
                                >

                                    {error && <>
                                        <motion.div
                                            initial={{ opacity: 0, marginBottom: 0 }}
                                            style={{ width: '100%' }}
                                            animate={{
                                                opacity: 1,
                                                marginBottom: 20
                                            }}>

                                            <Alert type={'error'} showIcon message={<p className="mb-0" key={0}>Something went wrong. Please contact your organization contact for assistance.</p>}></Alert>
                                            {/*
                                            {errors?.status === 'error' && }
                                            {(errors?.status === 'success' || errors?.status === 'info') && <Alert type={errors?.status} showIcon message={errors?.body}></Alert>}
                                        */}
                                        </motion.div>
                                    </>}

                                    <Form
                                        form={form}
                                        layout="vertical"
                                        requiredMark={true}
                                        onFinish={onEnroll}
                                    >

                                        <Row gutter={ROW_GUTTER}>
                                            <Col xs={24} sm={24} md={24} lg={24}>
                                                <Form.Item
                                                    name="first_name"
                                                    label="First Name"
                                                    rules={rules.first_name}
                                                >
                                                    <Input placeholder='Enter your first name' />
                                                </Form.Item>
                                                <Form.Item
                                                    name="last_name"
                                                    label="Last Name"
                                                    rules={rules.last_name}
                                                >
                                                    <Input placeholder='Enter your last name' />
                                                </Form.Item>
                                                <Form.Item
                                                    name="email"
                                                    label="Email"
                                                    rules={rules.email}
                                                >
                                                    <Input placeholder='Enter your email' />
                                                </Form.Item>
                                                <Form.Item
                                                    name="password"
                                                    label="Create Your Password"
                                                    rules={rules.password}
                                                    help='Your password must be 8-30 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character.'
                                                >
                                                    <Input.Password placeholder='Enter a password' />
                                                </Form.Item>

                                                <Form.Item
                                                    name="sms"
                                                    label={<><span className='mr-1'><FAIcon icon={faComments} className='app-fa-menu-item-icon mr-1' /></span> SMS</>}
                                                    initialValue={{ short: 'us' }}
                                                    validateTrigger={'onSubmit'}
                                                    rules={[
                                                        ({ getFieldValue }) => ({
                                                            validator(rule, value) {
                                                                if (value?.phone) {
                                                                    const isPossible = isPossiblePhoneNumber(value.phone, value.short)
                                                                    if (!isPossible) {
                                                                        return Promise.reject('Please enter a valid phone number');
                                                                    }
                                                                }
                                                                return Promise.resolve()
                                                            }
                                                        })
                                                    ]}
                                                >
                                                    <CountryPhoneInput
                                                        className='country-picker'
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="voice"
                                                    label={<><span className='mr-1'><FAIcon icon={faPhone} className='app-fa-menu-item-icon mr-1' /></span> Voice Call</>}
                                                    initialValue={{ short: 'us' }}
                                                    validateTrigger={'onSubmit'}
                                                    rules={[
                                                        ({ getFieldValue }) => ({
                                                            validator(rule, value) {
                                                                if (value?.phone) {
                                                                    const isPossible = isPossiblePhoneNumber(value.phone, value.short)
                                                                    if (!isPossible) {
                                                                        return Promise.reject('Please enter a valid phone number');
                                                                    }
                                                                }
                                                                return Promise.resolve()
                                                            }
                                                        })
                                                    ]}
                                                >
                                                    <CountryPhoneInput
                                                        className='country-picker'
                                                    />
                                                </Form.Item>

                                                <Form.Item name="terms" valuePropName="checked"
                                                    rules={rules.terms} >
                                                    <Checkbox><p>
                                                        By using PushPulse, you agree to our <a href={'https://pushpulse.com/terms-of-service'} target="_blank" rel="noopener noreferrer">Terms of Service</a>, <a href={'https://pushpulse.com/privacy-policy'} target="_blank" rel="noopener noreferrer">Privacy Policy</a>, and you agree that you may receive SMS messages from us.
                                                    </p></Checkbox>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" block loading={loadingIndicator}>
                                                        ENROLL
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </ConfigProvider>
                            </Flex>



                        </>
                    }
                </div>
            </div>

            <div className='request-footer'>
                <div className="container">
                    <Flex justifyContent="center" flexDirection="column" alignItems="center" className="py-2">
                        <PowerByLogo show_branding={!accountDetails ? true : accountDetails?.branding?.platform_branding} />
                    </Flex>
                </div>
            </div>
        </div>
    );
}

export default EnrollPage;
